import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-config";
import {
  Account,
  Broker,
  Comission,
  ComissionSheet,
} from "../types/BrokerAccount";
import { CommissionPercentageConfig, Seniority } from "../types/Employee";
import { getComissionsPercentageConfig } from "./ConfigCRUD";
import { getAccountByID, getAccount } from "./CustomerCRUD";
import { getEmployeeByID, getEmployees } from "./EmployeeCRUD";

export interface AccountList extends Account {
  id: string;
}

export interface MissingAccount {
  id: string;
  accountNumber: string;
  broker: Broker;
  customerName: string;
}

interface ReadComissionArrayResponse {
  success: boolean;
  missingAccounts?: MissingAccount[];
  message?: string;
}

export const readComissionArray = async (
  arrayOfComissions: ComissionSheet[],
  date: string
): Promise<ReadComissionArrayResponse> => {
  const accountInfo = arrayOfComissions.map((item) => {
    return {
      id: item.Corretora + "_" + item.Conta,
      accountNumber: item.Conta,
      broker: item.Corretora as Broker,
      customerName: item.Cliente,
    };
  });
  const allAccounts = (await getAccount()).map((account) => account.id);

  const missingAccounts = accountInfo.filter(
    (acc) => !allAccounts.includes(acc.id)
  );

  if (missingAccounts.length > 0)
    return {
      success: false,
      missingAccounts,
    };

  const config = await getComissionsPercentageConfig();
  const comissions: Comission[] = [];
  const employees = await getEmployees();
  const accounts: AccountList[] = await getAccount();
  try {
    for (const comission of arrayOfComissions) {
      comissions.push(calculaComissao(comission, config, employees, accounts));
    }
    const comissionsRef = doc(db, "Comission", date);
    await setDoc(comissionsRef, { comissions });

    return {
      success: true,
    };
  } catch (e) {
    return { success: false, message: e + "" };
  }
};

const calculaComissao = (
  comission: ComissionSheet,
  config: CommissionPercentageConfig,
  employees: {
    id: string;
    seniority: Seniority;
    email: string;
  }[],
  accounts: AccountList[]
) => {
  const accountNumber =
    comission.Corretora.toUpperCase() + "_" + comission.Conta;
  const clientAccount = accounts.find((item) => item.id === accountNumber);

  if (!clientAccount) throw `Conta ${clientAccount} não encontrada`;
  const mainAdvisorId = clientAccount.roles.mainAdvisorId;

  let mainAdvisorComission = 0;
  let mainAdvisorPercentage = 0;
  if (mainAdvisorId !== "ESCRITÓRIO" && mainAdvisorId !== "ESCRITORIO") {
    const mainAdvisor = employees.find((item) => item.id === mainAdvisorId);
    if (!mainAdvisor) throw `Assessor ${mainAdvisorId} não encontrado`;
    mainAdvisorPercentage = config[mainAdvisor.seniority].mainAdvisor / 100;
    mainAdvisorComission = comission.Comissão * mainAdvisorPercentage;
  }

  const supportAdvisorId = clientAccount.roles.supportAdvisorId;
  let supportAdvisorComission = 0;
  let supportAdvisorPercentage = 0;
  if (supportAdvisorId !== "ESCRITÓRIO" && supportAdvisorId !== "ESCRITORIO") {
    const supportAdvisor = employees.find(
      (item) => item.id === supportAdvisorId
    );
    if (!supportAdvisor) throw `Assessor ${supportAdvisorId} não encontrado`;
    supportAdvisorPercentage =
      config[supportAdvisor.seniority].supportAdvisor / 100;
    supportAdvisorComission = comission.Comissão * supportAdvisorPercentage;
  }

  const finderId = clientAccount.roles.finderId;

  let finderComission = 0;
  let finderPercentage = 0;
  if (finderId !== "ESCRITÓRIO" && finderId !== "ESCRITORIO") {
    const finder = employees.find((item) => item.id === finderId);
    if (!finder) throw `Finder ${finderId} não encontrado`;
    finderPercentage = config[finder.seniority].finder / 100;
    finderComission = comission.Comissão * finderPercentage;
  }

  const officePercentage =
    1 - mainAdvisorPercentage - supportAdvisorPercentage - finderPercentage;

  const comissionObject: Comission = {
    account: {
      accountNumber: accountNumber,
      broker: comission.Corretora as Broker,
    },
    distribution: [
      {
        employeeId: mainAdvisorId,
        value: mainAdvisorComission,
      },
      {
        employeeId: supportAdvisorId,
        value: supportAdvisorComission,
      },
      {
        employeeId: finderId,
        value: finderComission,
      },
      {
        employeeId: "ESCRITÓRIO",
        value: officePercentage * comission.Comissão,
      },
    ].filter((item) => item.value !== 0),
    totalValue: comission.Comissão,
    category: comission.Categoria,
  };

  return comissionObject;
};

export const getComissionDates = async () => {
  const comissionsCollectionRef = collection(db, "Comission");
  const comissions = await getDocs(comissionsCollectionRef);
  if (!comissions) {
    throw "Erro ao retornar comissões!";
  }

  const comissionDatesList: string[] = [];

  comissions.docs.map((doc) => comissionDatesList.push(doc.id));

  return comissionDatesList;
};

export const getComissions = async (date: string) => {
  const comissionsRef = doc(db, "Comission", date);
  const comissions = await getDoc(comissionsRef);
  if (!comissions) {
    throw "Erro ao retornar as comissões!";
  }

  return comissions.data() as {
    comissions: Comission[];
  };
};
