import { collection, doc, setDoc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase-config";
import { Account, SheetObject } from "../types/BrokerAccount";

const AccountsCollectionRef = collection(db, "BrokerAccount");

export const getAccount = async () => {
  const accounts = await getDocs(AccountsCollectionRef);
  if (!accounts) throw "Erro ao retornar clientes!";

  const accountList = accounts.docs.map((doc) => ({
    ...(doc.data() as Account),
    id: doc.id,
  }));

  return accountList.sort((a, b) => {
    const textA = a.customerName;
    const textB = b.customerName;
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
};

export const getAccountByID = async (id: string) => {
  const AccountRef = doc(db, "BrokerAccount", id);
  const account = await getDoc(AccountRef);
  if (!account) throw "Erro ao retornar cliente!";

  return account.data() as Account;
};

export const readAccountArray = async (array: Array<SheetObject>) => {
  const promises: Array<Promise<any>> = [];

  array.forEach((account) => {
    if (
      account["Genial"] &&
      account["Genial"] !== "-" &&
      account["Genial"] !== " " &&
      account["Genial"].length !== 0
    )
      promises.push(
        createAccount(
          account,
          "GENIAL_" + (account["Genial"] + "").replaceAll("/", "_")
        )
      );
    if (
      account["Necton"] &&
      account["Necton"] !== "-" &&
      account["Necton"] !== " " &&
      account["Necton"].length !== 0
    )
      promises.push(
        createAccount(
          account,
          "NECTON_" + (account["Necton"] + "").replaceAll("/", "_")
        )
      );
    if (
      account["Órama"] &&
      account["Órama"] !== "-" &&
      account["Órama"] !== " " &&
      account["Órama"].length !== 0
    )
      promises.push(
        createAccount(
          account,
          "ÓRAMA_" + (account["Órama"] + "").replaceAll("/", "_")
        )
      );
    if (
      account["Guide"] &&
      account["Guide"] !== "-" &&
      account["Guide"] !== " " &&
      account["Guide"].length !== 0
    )
      promises.push(
        createAccount(
          account,
          "GUIDE_" + (account["Guide"] + "").replaceAll("/", "_")
        )
      );
    if (
      account["Ágora"] &&
      account["Ágora"] !== "-" &&
      account["Ágora"] !== " " &&
      account["Ágora"].length !== 0
    )
      promises.push(
        createAccount(
          account,
          "ÁGORA_" + (account["Ágora"] + "").replaceAll("/", "_")
        )
      );
  });

  return await Promise.all(promises);
};

export const createAccount = async (obj: SheetObject, key: string) => {
  const account = {
    customerName: obj["NOME"],
    segment: obj["Segmento"],
    roles: {
      mainAdvisorId: obj["Assessor Principal"],
      supportAdvisorId: obj["Assessor Apoio"],
      finderId: obj["Finder"],
    },
    customerCpf: obj["CPF/CNPJ"] ? obj["CPF/CNPJ"] : 0,
    observations: obj["Observações"] ? obj["Observações"] : "",
  };

  const accountRef = doc(db, "BrokerAccount", key);
  return await setDoc(accountRef, account).then(() =>
    console.log(account.customerName, key)
  );
};
