import React, { useState } from "react";
import * as xlsx from "xlsx";
import { button, disabledButton } from "../../styles/components";
import Spacer from "../../components/Spacer";
import "react-activity/dist/library.css";
import { ComissionSheet } from "../../types/BrokerAccount";
import ProgressBar from "@ramonak/react-progress-bar";
import { MONTHS_LABEL } from "../../util";
import { useParams } from "react-router";
import {
  MissingAccount,
  readComissionArray,
} from "../../controllers/ComissionCRUD";

export default () => {
  const { month, year } = useParams();

  const [progress, setProgress] = useState(0);
  const [missingData, setMissingData] = useState<MissingAccount[]>();
  const [error, setError] = useState("");

  const missingDataIds = (missingData || []).map(
    (item) => `${item.broker}_${item.accountNumber} - ${item.customerName}`
  );
  const uniqueMissingDataIds = new Set(missingDataIds);

  const [eventFile, setEventFile] =
    useState<React.ChangeEvent<HTMLInputElement>>();

  const sendEnabled = !!eventFile;

  return (
    <>
      <h1 style={{ textAlign: "center" }}>Enviar planilha de comissões</h1>
      <h2 style={{ textAlign: "center" }}>
        {/**@ts-ignore */}
        {MONTHS_LABEL[month!]} de {year}
      </h2>
      <input
        type="file"
        onChange={(e) => setEventFile(e)}
        accept=".xlsx, .xls"
      />
      <Spacer size={32} />
      {!progress && (
        <button
          style={sendEnabled ? button : disabledButton}
          disabled={!sendEnabled}
          onClick={readUploadFile}
        >
          {"Processar planilha"}
        </button>
      )}
      <Spacer size={32} />
      {!!progress && <ProgressBar completed={progress} />}
      {!!uniqueMissingDataIds.size && (
        <>
          <h2>Falha ao atualizar comissões:</h2>
          <p>
            <b>Não foi possível encontrar a(s) seguinte(s) contas:</b>
          </p>
          {(Array.from(uniqueMissingDataIds) || []).map((item) => (
            <React.Fragment key={item}>
              <label>{item}</label>
              <Spacer size={8} />
            </React.Fragment>
          ))}
        </>
      )}
      {!!error && (
        <>
          <h2>Falha ao atualizar comissões:</h2>
          <p>
            <b>{error}</b>
          </p>
        </>
      )}
    </>
  );

  async function readUploadFile() {
    setProgress(10);
    console.log("Iniciando leitura da planilha...");

    setMissingData(undefined);
    if (!eventFile) return;
    setProgress(20);

    eventFile.preventDefault();
    if (eventFile.target.files) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target?.result;
        console.log("Lendo os dados...");
        setProgress(30);
        const workbook = xlsx.read(data, { type: "array" });
        console.log("Processando os dados...");
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(
          worksheet
        ) as Array<ComissionSheet>;
        setProgress(70);

        const formattedMonth = ("00" + month).slice(-2);
        const date = formattedMonth + "-" + year;
        const readResponse = await readComissionArray(json, date);
        if (readResponse.success) {
          setProgress(100);
          return;
        }
        setMissingData(readResponse.missingAccounts);
        setError(readResponse.message || "");
        setProgress(0);
      };

      reader.readAsArrayBuffer(eventFile.target.files[0]);
    }
  }
};
