import React, { useState } from "react";
import * as xlsx from "xlsx";
import { button, disabledButton } from "../../styles/components";
import Spacer from "../../components/Spacer";
import "react-activity/dist/library.css";
import { SheetObject } from "../../types/BrokerAccount";
import { readAccountArray } from "../../controllers/CustomerCRUD";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate } from "react-router";

export default () => {
  const [progress, setProgress] = useState(0);

  const [eventFile, setEventFile] =
    useState<React.ChangeEvent<HTMLInputElement>>();

  const sendEnabled = !!eventFile;

  const navigate = useNavigate();

  return (
    <>
      <h1 style={{ textAlign: "center" }}>Enviar planilha de clientes</h1>
      {progress < 100 && (
        <input
          type="file"
          onChange={(e) => setEventFile(e)}
          accept=".xlsx, .xls"
        />
      )}
      <Spacer size={32} />
      {!progress && (
        <button
          style={sendEnabled ? button : disabledButton}
          disabled={!sendEnabled}
          onClick={readUploadFile}
        >
          {"Processar planilha"}
        </button>
      )}
      <Spacer size={32} />
      {!!progress && <ProgressBar completed={progress} />}
      {progress === 100 && (
        <>
          <Spacer size={32} />
          <div style={styles.buttonContainer}>
            <button type="button" style={button} onClick={() => navigate("/")}>
              Finalizar
            </button>
          </div>
        </>
      )}
    </>
  );

  async function readUploadFile() {
    if (!eventFile) return;
    setProgress(5);

    eventFile.preventDefault();
    if (eventFile.target.files) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target?.result;
        console.log("Lendo os dados...");
        setProgress(10);
        const workbook = xlsx.read(data, { type: "array" });
        console.log("Processando os dados...");
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, {
          blankrows: false,
        }) as Array<SheetObject>;
        setProgress(30);
        await readAccountArray(json);
        setProgress(100);
      };

      reader.readAsArrayBuffer(eventFile.target.files[0]);
    }
  }
};

const styles = {
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
  },
};
